/** @jsx jsx */
import { jsx } from "theme-ui"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const PrivacyPolicy = () => (
  <Layout className="privacy-policy-page">
    <SEO 
      title="Privacy Policy | HR Tools & Software" 
      description="Our commitment to protecting your privacy and data security when using our HR tools and services."
      keywords={[
        "privacy policy",
        "data protection",
        "GDPR compliance",
        "HR data security",
        "information privacy",
        "CCPA compliance",
        "HIPAA compliance",
        "employee data protection"
      ]}
    />
    <div className="wrapper" sx={{ 
      maxWidth: '1000px', 
      margin: '0 auto', 
      padding: '40px 20px'
    }}>
      {/* Quick Navigation */}
      <div sx={{
        bg: 'white',
        p: 4,
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        mb: 4
      }}>
        <h2 sx={{ fontSize: '1.2rem', mb: 3, color: 'primary' }}>Quick Navigation</h2>
        <div sx={{ 
          display: 'grid', 
          gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr 1fr'],
          gap: 3
        }}>
          <a href="#overview" sx={{ 
            color: 'primary',
            textDecoration: 'none',
            '&:hover': { textDecoration: 'underline' }
          }}>Overview</a>
          <a href="#data-collection" sx={{ 
            color: 'primary',
            textDecoration: 'none',
            '&:hover': { textDecoration: 'underline' }
          }}>Data Collection</a>
          <a href="#hr-specific" sx={{ 
            color: 'primary',
            textDecoration: 'none',
            '&:hover': { textDecoration: 'underline' }
          }}>HR Data Protection</a>
          <a href="#compliance" sx={{ 
            color: 'primary',
            textDecoration: 'none',
            '&:hover': { textDecoration: 'underline' }
          }}>Compliance</a>
          <a href="#security" sx={{ 
            color: 'primary',
            textDecoration: 'none',
            '&:hover': { textDecoration: 'underline' }
          }}>Security</a>
          <a href="#contact" sx={{ 
            color: 'primary',
            textDecoration: 'none',
            '&:hover': { textDecoration: 'underline' }
          }}>Contact</a>
        </div>
      </div>

      <h1 sx={{ 
        fontSize: ['1.75rem', '2rem', '2.25rem'], 
        mb: 4,
        color: 'primary',
        textAlign: 'center'
      }}>
        Privacy Policy
      </h1>

      <div sx={{ 
        bg: 'white', 
        p: 4, 
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        mb: 4
      }}>
        <div sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 4,
          pb: 3,
          borderBottom: '1px solid',
          borderColor: 'gray.2'
        }}>
          <p sx={{ fontSize: '1.1rem', lineHeight: 1.6 }}>
            Last updated: {new Date().toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
          </p>
          <Link to="/terms" sx={{ 
            color: 'primary',
            textDecoration: 'none',
            '&:hover': { textDecoration: 'underline' }
          }}>
            View Terms of Service
          </Link>
        </div>

        <section id="overview" sx={{ mb: 5 }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Overview</h2>
          <div sx={{
            p: 3,
            bg: 'gray.1',
            borderRadius: '4px',
            mb: 3
          }}>
            <p sx={{ mb: 3, lineHeight: 1.6 }}>
              We are committed to protecting your privacy and ensuring the security of your data. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our HR tools and services.
            </p>
            <p sx={{ lineHeight: 1.6 }}>
              As an HR technology provider, we understand the sensitive nature of employee and organizational data. We adhere to strict privacy standards and comply with major data protection regulations including GDPR, CCPA, and relevant HR data protection laws.
            </p>
          </div>
        </section>

        <section id="data-collection" sx={{ mb: 5 }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Data Collection & Usage</h2>
          
          <h3 sx={{ fontSize: '1.2rem', mb: 2, color: 'text' }}>Information We Collect</h3>
          <ul sx={{ mb: 4, pl: 4, '& li': { mb: 2, lineHeight: 1.6 } }}>
            <li>Tool usage data and analytics</li>
            <li>Assessment responses and calculations</li>
            <li>User preferences and settings</li>
            <li>Technical information about your browser and device</li>
            <li>Anonymous usage statistics</li>
          </ul>

          <div sx={{
            bg: 'primary',
            color: 'white',
            p: 3,
            borderRadius: '4px',
            mb: 4
          }}>
            <strong>Important Note:</strong> We do not collect or store sensitive employee data such as social security numbers, financial information, or personal health information.
          </div>

          <h3 sx={{ fontSize: '1.2rem', mb: 2, color: 'text' }}>How We Use Your Data</h3>
          <ul sx={{ mb: 4, pl: 4, '& li': { mb: 2, lineHeight: 1.6 } }}>
            <li>Provide and improve our HR tools and services</li>
            <li>Generate assessment results and recommendations</li>
            <li>Analyze tool performance and usage patterns</li>
            <li>Enhance user experience and functionality</li>
            <li>Maintain and optimize our services</li>
          </ul>
        </section>

        <section id="hr-specific" sx={{ mb: 5 }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>HR-Specific Data Protection</h2>
          
          <h3 sx={{ fontSize: '1.2rem', mb: 2, color: 'text' }}>Employee Data Protection</h3>
          <ul sx={{ mb: 4, pl: 4, '& li': { mb: 2, lineHeight: 1.6 } }}>
            <li>Compliance with employment data protection laws</li>
            <li>Special handling of diversity and inclusion data</li>
            <li>Protection of performance review information</li>
            <li>Secure handling of compensation data</li>
            <li>Confidentiality of recruitment information</li>
          </ul>

          <h3 sx={{ fontSize: '1.2rem', mb: 2, color: 'text' }}>Industry Compliance</h3>
          <ul sx={{ mb: 4, pl: 4, '& li': { mb: 2, lineHeight: 1.6 } }}>
            <li>HIPAA compliance for health-related data</li>
            <li>SOC 2 Type II certification</li>
            <li>ISO 27001 information security standards</li>
            <li>Regular compliance audits and certifications</li>
          </ul>
        </section>

        <section id="compliance" sx={{ mb: 5 }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Regulatory Compliance</h2>
          
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr 1fr'],
            gap: 4,
            mb: 4
          }}>
            <div sx={{
              p: 3,
              border: '1px solid',
              borderColor: 'gray.2',
              borderRadius: '4px'
            }}>
              <h3 sx={{ fontSize: '1.2rem', mb: 2, color: 'text' }}>GDPR Compliance</h3>
              <ul sx={{ pl: 4, '& li': { mb: 2, lineHeight: 1.6 } }}>
                <li>Right to access personal data</li>
                <li>Right to rectification</li>
                <li>Right to erasure</li>
                <li>Right to restrict processing</li>
                <li>Right to data portability</li>
                <li>Right to object</li>
              </ul>
            </div>

            <div sx={{
              p: 3,
              border: '1px solid',
              borderColor: 'gray.2',
              borderRadius: '4px'
            }}>
              <h3 sx={{ fontSize: '1.2rem', mb: 2, color: 'text' }}>CCPA Compliance</h3>
              <ul sx={{ pl: 4, '& li': { mb: 2, lineHeight: 1.6 } }}>
                <li>Right to know what data is collected</li>
                <li>Right to delete personal information</li>
                <li>Right to opt-out of data sales</li>
                <li>Right to non-discrimination</li>
                <li>Annual privacy notice</li>
                <li>Data collection disclosure</li>
              </ul>
            </div>
          </div>

          <h3 sx={{ fontSize: '1.2rem', mb: 2, color: 'text' }}>Additional Regulations</h3>
          <ul sx={{ mb: 4, pl: 4, '& li': { mb: 2, lineHeight: 1.6 } }}>
            <li>State-specific privacy laws compliance</li>
            <li>International data transfer regulations</li>
            <li>Industry-specific requirements</li>
            <li>Regular compliance updates</li>
          </ul>
        </section>

        <section id="security" sx={{ mb: 5 }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Security Measures</h2>
          
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr 1fr 1fr'],
            gap: 4,
            mb: 4
          }}>
            <div sx={{
              textAlign: 'center',
              p: 3,
              bg: 'gray.1',
              borderRadius: '4px'
            }}>
              <h4 sx={{ fontSize: '1.1rem', mb: 2 }}>Data Encryption</h4>
              <p>Industry-standard encryption for all data transmission and storage</p>
            </div>
            <div sx={{
              textAlign: 'center',
              p: 3,
              bg: 'gray.1',
              borderRadius: '4px'
            }}>
              <h4 sx={{ fontSize: '1.1rem', mb: 2 }}>Access Control</h4>
              <p>Strict authentication and authorization protocols</p>
            </div>
            <div sx={{
              textAlign: 'center',
              p: 3,
              bg: 'gray.1',
              borderRadius: '4px'
            }}>
              <h4 sx={{ fontSize: '1.1rem', mb: 2 }}>Regular Audits</h4>
              <p>Continuous security monitoring and testing</p>
            </div>
          </div>
        </section>

        <section id="contact" sx={{ mb: 5 }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Contact Information</h2>
          
          <div sx={{
            mb: 4,
            p: 3,
            bg: 'primaryMuted',
            borderRadius: '6px',
            textAlign: 'center'
          }}>
            <p sx={{ mb: 3, fontSize: '1.1rem', lineHeight: 1.6 }}>
              For privacy-related inquiries or to exercise your data rights, please contact us through our secure form:
            </p>
            <Link to="/contact" sx={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: 2,
              bg: 'primary',
              color: 'white',
              py: 2,
              px: 4,
              borderRadius: '6px',
              fontSize: '1rem',
              fontWeight: 600,
              textDecoration: 'none',
              transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                color: 'white'
              }
            }}>
              Contact Us
            </Link>
          </div>

          <div>
            <h3 sx={{ fontSize: '1.2rem', mb: 2, color: 'text' }}>Response Times</h3>
            <ul sx={{ pl: 4, '& li': { mb: 2, lineHeight: 1.6 } }}>
              <li>General inquiries: 1-2 business days</li>
              <li>Data access requests: 30 days</li>
              <li>Urgent privacy concerns: 24 hours</li>
            </ul>
          </div>
        </section>

        <div sx={{
          mt: 5,
          pt: 4,
          borderTop: '1px solid',
          borderColor: 'gray.2',
          textAlign: 'center'
        }}>
          <p sx={{ fontSize: '0.9rem', color: 'gray.7' }}>
            For more information about our practices, please review our{' '}
            <Link to="/terms" sx={{ color: 'primary' }}>Terms of Service</Link> or{' '}
            <a href="#overview" sx={{ color: 'primary' }}>return to top</a>.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default PrivacyPolicy 